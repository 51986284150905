import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Keys} from "../common/keys";
import {Storage} from "@ionic/storage-angular";
import * as Keycloak from "keycloak-ionic";
import {Router} from "@angular/router";
import {ApiRoutes} from "../common/api-routes";
import {Prodotto} from "../models/Prodotto";
import {Observable} from "rxjs";
import {User} from "../models/User";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // base: string = 'http://31.3.135.34/'
  baseApi: string = 'https://api-ifa.ms3dev.it/public/'
  baseImg: string = 'https://api-ifa.ms3dev.it/storage/app/'
  base: string = 'https://dashboard.ifa-suisse.com/'
  client_id = 'mobile'
  client_secret = ''
  errors = []
  users: User[] = []
  public user: any;
  public userSetted: EventEmitter<User>;

  public keycloak: Keycloak.KeycloakInstance;
  public userProfile: Keycloak.KeycloakProfile;

  constructor(private http: HttpClient, private storage: Storage, private router: Router) {

    this.userSetted = new EventEmitter();

    this.keycloak = Keycloak({
      clientId: 'mobile',
      realm: 'ifa',
      url: this.base
    });

    this.keycloak.init({
      adapter: 'default',
      responseMode: 'query',
      // redirectUri: 'http://localhost:4200/',
      redirectUri: 'https://partner-ifa.ms3dev.it/',
      checkLoginIframe: false
    });

    this.storage.create()

    this.getData(ApiRoutes.users, (res: any) => {
      this.users = res
      console.log(this.users)
      if(this.users) {
        this.getToken()
        this.user = this.users.find(u => u.sub == this.getToken().sub)
        console.log(this.user)
      }
    })


    // this.user = this.users.find(u => u.sub == this.keycloak)
  }

  delete(to: ApiRoutes, u_id: string) {
    return this.http.get(this.baseApi + to + '/' + u_id)
  }

  setUser(user: User) {
    this.user = user
    this.userSetted.emit(user)
  }

  loggedIn() {
    return localStorage.getItem('token') != null
  }

  logout() {
    localStorage.removeItem('token')
    this.router.navigate(['home'])
  }

  getToken() {
    if (!this.loggedIn()) {
      return null
    }
    var token = localStorage.getItem('token').split('.')[1]
    // console.log(JSON.parse(atob(token)))
    return JSON.parse(atob(token))
  }


  me() {
    var token = this.getToken()
    var users = []
    var user = null
    this.getData(ApiRoutes.users, (res: any) => {
      users = res
      if (users.length > 0) {
        this.user = users.find(u => u.sub == token.sub)
        console.log(this.user)
        return this.user
      }
    })
  }

  is(ruolo: string) {
    var token = this.getToken()
    if (!token) {
      return false
    }
    return token.realm_access.roles.indexOf(ruolo) > -1
  }


  get(route: string = null, callback) {
    this.doRefresh(() => {
      this.http.get(this.base + (route || 'user'), {withCredentials: false}).subscribe(res => {
        callback(res)
      }, error => {
        callback(null, error)
      })
    })
  }

  getData(to: ApiRoutes, callback: any) {
    this.http.get(this.baseApi + to).subscribe((res: any) => {
      callback(res)
    })
  }

  getById(to: ApiRoutes, id: any, callback: any) {
    this.http.get(this.baseApi + to + '/' + id).subscribe((res: any) => {
      callback(res)
    })
  }

  post(data: any, callback: any) {
    this.errors = []
    var options = {
      // headers: {'Content-Type': 'application/json'}
    }

    this.http.post(this.baseApi + 'prodotto_app', data, options).subscribe(res => {
      callback(res)
    })
  }

  postData(to: ApiRoutes, data: any, callback: any) {
    this.http.post(this.baseApi + to, data).subscribe((res) => {
      callback(res)
    })
  }

  put(to: ApiRoutes, data: any, callback: any) {
    this.errors = []
    var options = {
      // headers: {'Content-Type': 'application/json'}
    }

    this.http.post(this.baseApi + to + '/' + data.u_id, data, options).subscribe(res => {
      callback(res)
    })
  }

  async getRefreshBody() {
    let body = new URLSearchParams();
    body.set('client_id', this.client_id);
    body.set('client_secret', this.client_secret);
    body.set('scope', 'openid');
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', await this.storage.get(Keys.refresh_token) as string);
    return body
  }

  async doRefresh(callback: any) {
    console.log('doing refresh')
    this.http.post(this.base + 'realms/ifa/protocol/openid-connect/token', (await this.getRefreshBody()).toString(), {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      withCredentials: false
    }).subscribe(async (res: any) => {
      console.log(res)
      await this.storage.set(Keys.token, res.access_token)
      await this.storage.set(Keys.refresh_token, res.refresh_token)
      //localStorage.setItem(Keys.expires_in, res.expires_in)
      //localStorage.setItem(Keys.last_refresh, String(Math.floor(new Date().getTime() / 1000)))
      callback(res)
    }, async (error: any) => {
      console.log(error)
      await this.storage.remove(Keys.token)
      await this.storage.remove(Keys.refresh_token)
      await this.router.navigate(['/home'], {replaceUrl: true})
    })
  }

  isTokenValid(token: string) {
    this.http.get(this.base + 'decode/' + token).subscribe((res: any) => {
      if (res) {
        return true
      }
    })
  }

  getElementById(to: ApiRoutes, u_id: string, callback: any) {
    this.http.get(this.baseApi + to + '/' + u_id).subscribe((res: any) => {
      callback(res)
    })
  }

  postProdotto(to: ApiRoutes, nome: string, crediti: any, descrizione: string, indirizzo: string, telefono: string, whatsapp: string, partner_id: any, file: File): Observable<any> {
    this.errors = []
    if (nome == '' || nome == null) {
      this.errors.push('Il campo nome è obbligatorio!')
    }
    // if (prezzo == '' || prezzo == null) {
    //   this.errors.push('Il campo prezzo è obbligatorio!')
    // }
    if (crediti == '' || crediti == null) {
      this.errors.push('Il campo crediti è obbligatorio!')
    }
    if (descrizione == '' || descrizione == null) {
      this.errors.push('Il campo descrizione è obbligatorio!')
    }
    if (indirizzo == '' || indirizzo == null) {
      this.errors.push('Il campo indirizzo è obbligatorio!')
    }
    if (telefono == '' || telefono == null) {
      this.errors.push('Il campo telefono è obbligatorio!')
    }
    if (whatsapp == '' || whatsapp == null) {
      this.errors.push('Il campo whatsapp è obbligatorio!')
    }
    if (partner_id == '' || partner_id == null) {
      this.errors.push('Il campo partner è obbligatorio!')
    }
    if (!file) {
      this.errors.push('Il campo immagine è obbligatorio!')
    }
    const formData = new FormData()
    formData.append("nome", nome)
    // formData.append("prezzo", prezzo)
    formData.append("crediti", crediti)
    formData.append("descrizione", descrizione)
    formData.append("indirizzo", indirizzo)
    formData.append("telefono", telefono)
    formData.append("whatsapp", whatsapp)
    formData.append("partner_id", partner_id)
    if (file) {
      formData.append("immagine", file, file.name)
    }

    if (this.errors.length == 0) {
      return this.http.post(this.baseApi + to, formData)
    } else {
      return
    }
  }

  putProdotto(u_id: string, to: ApiRoutes, nome: string, crediti: any, descrizione: string, indirizzo: string, telefono: string, whatsapp: string, partner_id: any, file?: File): Observable<any> {
    this.errors = []
    if (nome == '' || nome == null) {
      this.errors.push('Il campo nome è obbligatorio!')
    }
    // if (prezzo == '' || prezzo == null) {
    //   this.errors.push('Il campo prezzo è obbligatorio!')
    // }
    if (crediti == '' || crediti == null) {
      this.errors.push('Il campo crediti è obbligatorio!')
    }
    if (descrizione == '' || descrizione == null) {
      this.errors.push('Il campo descrizione è obbligatorio!')
    }
    if (indirizzo == '' || indirizzo == null) {
      this.errors.push('Il campo indirizzo è obbligatorio!')
    }
    if (telefono == '' || telefono == null) {
      this.errors.push('Il campo telefono è obbligatorio!')
    }
    if (whatsapp == '' || whatsapp == null) {
      this.errors.push('Il campo whatsapp è obbligatorio!')
    }
    if (partner_id == '' || partner_id == null) {
      this.errors.push('Il campo partner è obbligatorio!')
    }
    const formData = new FormData()
    formData.append("nome", nome)
    // formData.append("prezzo", prezzo)
    formData.append("crediti", crediti)
    formData.append("descrizione", descrizione)
    formData.append("indirizzo", indirizzo)
    formData.append("telefono", telefono)
    formData.append("whatsapp", whatsapp)
    formData.append("partner_id", partner_id)
    if (file) {
      formData.append("immagine", file, file.name)
    }

    if (this.errors.length == 0) {
      return this.http.post(this.baseApi + to + '/' + u_id, formData)
    } else {
      return
    }

  }

  removePointUser(sub: string, point: number, data, callback: any) {
    this.http.post(this.baseApi + 'point_user/remove/' + sub + '/' + point, data).subscribe((res: any) => {
      callback(res)
    })
  }


}
