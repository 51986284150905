import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-home-bottom',
  templateUrl: './home-bottom.component.html',
  styleUrls: ['./home-bottom.component.scss'],
})
export class HomeBottomComponent implements OnInit {
  @Input() registered: boolean = false

  constructor() {
  }

  ngOnInit() {
  }

}
