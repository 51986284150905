import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {ApiService} from "../services/api.service";

@Injectable({
  providedIn: 'root'
})
export class NegozioGuard  {
  constructor(private router: Router, private api: ApiService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.api.is('negozio')) {
       return true
    } else {
      return this.router.navigate(['/home'])
    }
  }

}
