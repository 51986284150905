import {Injectable} from '@angular/core';
import {Storage} from "@ionic/storage-angular";
import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {from, Observable} from "rxjs";
import {Keys} from "../common/keys";
import {switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private _storage: Storage) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler)
    : Observable<HttpEvent<any>> {
    return from(this._storage.get(Keys.token)).pipe(
      switchMap(token => {
        if (token) {
          const authReq = req.clone({
            headers: req.headers.set('Authorization', 'bearer ' + token)
          });
          return next.handle(authReq)
        }
        return next.handle(req)
      })
    )
  }
}
