import {Component, Input, OnInit} from '@angular/core';
import {Partner} from "../../models/Partner";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-card-prodotto',
  templateUrl: './card-prodotto.component.html',
  styleUrls: ['./card-prodotto.component.scss'],
})
export class CardProdottoComponent implements OnInit {
  @Input() nome: string = null
  @Input() descrizione: string = null
  @Input() immagine: string = null
  @Input() prezzo: string = null
  @Input() crediti: number = null
  @Input() partner: string = null
  @Input() whatsapp: string = null
  @Input() indirizzo: string = null
  @Input() telefono: string = null
  constructor(public api: ApiService) { }

  ngOnInit() {}

}
