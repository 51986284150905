import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoComponent} from "../components/logo/logo.component";
import {HomeUnregisteredComponent} from "../components/home-unregistered/home-unregistered.component";
import {IonicModule} from "@ionic/angular";
import {HomeBottomComponent} from "../components/home-bottom/home-bottom.component";
import {HomeRegisteredComponent} from "../components/home-registered/home-registered.component";
import {SwiperModule} from "swiper/angular";
import {SliderTextComponent} from "../components/slider-text/slider-text.component";
import {CardProdottoComponent} from "../components/card-prodotto/card-prodotto.component";
import {ProdottoComponent} from "../components/prodotto/prodotto.component";
import {HomeNegozioComponent} from "../components/home-negozio/home-negozio.component";
import {FormsModule} from "@angular/forms";
import {FormProdottoComponent} from "../components/form-prodotto/form-prodotto.component";
import {ErrorComponent} from "../components/error/error.component";
import {QRCodeModule} from 'angularx-qrcode';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {PartnerComponent} from "../components/partner/partner.component";
import {ProfileComponent} from "../components/profile/profile.component";
import {DialogScannerComponent} from "../components/dialog-scanner/dialog-scanner.component";

@NgModule({
  declarations: [DialogScannerComponent, LogoComponent, HomeUnregisteredComponent, HomeBottomComponent, HomeRegisteredComponent, SliderTextComponent, CardProdottoComponent, ProdottoComponent, HomeNegozioComponent, FormProdottoComponent, ErrorComponent, PartnerComponent, ProfileComponent],
  exports: [
    LogoComponent,
    HomeUnregisteredComponent,
    HomeRegisteredComponent,
    CardProdottoComponent,
    HomeNegozioComponent,
    ProdottoComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    QRCodeModule,
    SwiperModule,
    FormsModule,
    ZXingScannerModule
  ]
})
export class SharedModule {
}
