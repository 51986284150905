import {Component, OnInit} from '@angular/core';
import {Prodotto} from "../../models/Prodotto";
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Route} from "@angular/router";
import {ApiRoutes} from "../../common/api-routes";
import {MatDialog} from "@angular/material/dialog";
import {DialogScannerComponent} from "../dialog-scanner/dialog-scanner.component";

@Component({
  selector: 'app-prodotto',
  templateUrl: './prodotto.component.html',
  styleUrls: ['./prodotto.component.scss'],
})
export class ProdottoComponent implements OnInit {
  showScanner = false
  numberScan = 1
  prodotto: Prodotto
  id: number
  ruolo: string

  constructor(public api: ApiService, public route: ActivatedRoute, public dialog: MatDialog) {
    this.id = route.snapshot.params['id']
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(DialogScannerComponent, {
      width: '100%',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  ngOnInit() {
      this.api.getById(ApiRoutes.prodotto, this.id, (res: any) => {
        if(res) {
          this.prodotto = res
          console.log(this.prodotto)
        }
      })

    this.ruolo = localStorage.getItem('ruolo')
    console.log(this.ruolo)
  }

  scanSuccessHandler(event: any, prodottoId: number) {
    var user = this.api.users.find(u => u.sub == event)
    if(this.numberScan == 1) {
      console.log(user)
      console.log(event)
      if(user.numero_punti > this.prodotto.crediti) {
        this.api.removePointUser(event, prodottoId, user, (res: any) => {
          this.showScanner = false
          alert('Crediti sottratti con successo!')
        })
      } else {
        this.showScanner = false
        alert('crediti insufficienti!')
      }
      this.numberScan++
    }
  }

  scanner() {
    this.showScanner = true
  }

  closeScanner() {
    this.showScanner = false
  }

}
