import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Storage} from "@ionic/storage-angular";
import {ApiService} from "./services/api.service";
import {User} from "./models/User";
import {ApiRoutes} from "./common/api-routes";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private storage: Storage, public api: ApiService) {

  }

  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    await this.storage.create();
  }

}
