import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-scanner',
  templateUrl: './dialog-scanner.component.html',
  styleUrls: ['./dialog-scanner.component.scss'],
})
export class DialogScannerComponent implements OnInit {
  numberScan = 1
  constructor() { }

  ngOnInit() {}

  scanSuccessHandler(event: any) {
    if(this.numberScan == 1) {
      console.log(event)
      this.numberScan++
    }
  }

}
