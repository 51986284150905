import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {ApiRoutes} from "../../common/api-routes";
import {Partner} from "../../models/Partner";
import {Prodotto} from "../../models/Prodotto";

@Component({
  selector: 'app-home-negozio',
  templateUrl: './home-negozio.component.html',
  styleUrls: ['./home-negozio.component.scss'],
})
export class HomeNegozioComponent implements OnInit {
  prodotto: Prodotto
  prodotti = []
  s_prodotti = []
  partner: Partner[] = []
  constructor(public api: ApiService, public router: Router) { }

  ngOnInit() {
    // this.api.getData(ApiRoutes.prodotti, (res: any) => {
    //   this.prodotti = res
    //   this.s_prodotti = res
    //   console.log(res)
    // })

    this.api.getById(ApiRoutes.prodotti_partner, this.api.getToken().sub, (res: any) => {
      this.prodotti = res
      console.log(this.prodotti)
    })

    this.api.getData(ApiRoutes.partner, (res: any) => {
      this.partner = res
      console.log(this.partner)
    })
  }

  goToProdotti() {
    this.router.navigate(['prodotti'])
  }

  goToAddProdotto() {
    this.router.navigate(['add_prodotto'])
  }

  goToEditProdotto(u_id: string) {
    this.router.navigate(['edit_prodotto/' + u_id])
  }

  goToProdotto(u_id:string) {
    this.router.navigate(['prodotto/' + u_id])
  }

  filterPartner(event) {
    var partnerId = event.target.value
    if(partnerId == '') {
      this.prodotti = this.s_prodotti
    } else {
      this.prodotti = this.s_prodotti.filter(p => p.partner_id == partnerId)
    }
  }

  deleteProdotto(u_id: string) {
    if(confirm('Sei sicuro di voler eliminare questo prodotto?')) {
      this.api.delete(ApiRoutes.delete_prodotto, u_id).subscribe((res: any) => {
        this.ngOnInit()
      })
    }
  }

}
