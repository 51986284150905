import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../services/api.service";
import {Partner} from "../../models/Partner";
import {ApiRoutes} from "../../common/api-routes";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {
  partner: Partner = {}
  id: string
  constructor(public api: ApiService, public route: ActivatedRoute, private router: Router) {
    this.id = route.snapshot.params['id']
    console.log(this.id)
  }

  ngOnInit() {
    this.api.getElementById(ApiRoutes.partner, this.id, (res: any)=> {
      this.partner = res
      console.log(this.partner)
    })
  }

  goToProdotto(u_id: string) {
    this.router.navigate(['prodotto/' + u_id])
  }

}
