import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-slider-text',
  templateUrl: './slider-text.component.html',
  styleUrls: ['./slider-text.component.scss'],
})
export class SliderTextComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
