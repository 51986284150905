import {Component, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Prodotto} from "../../models/Prodotto";
import {ApiService} from "../../services/api.service";
import {ApiRoutes} from "../../common/api-routes";
import {Route, Router} from "@angular/router";
import {User} from "../../models/User";
import {PointUser} from "../../models/PointUser";
import {Partner} from "../../models/Partner";

@Component({
  selector: 'app-home-registered',
  templateUrl: './home-registered.component.html',
  styleUrls: ['./home-registered.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeRegisteredComponent implements OnInit {
  userPoint: PointUser[] = []
  @Input() user = null
  prodotti: Prodotto[] = []
  users: User[] = []
  qrCode = false
  partners: Partner[] = []
  lastCredits = false
  userLogged: any

  constructor(public api: ApiService, public router: Router) {
  }

  ngOnInit() {
    this.api.getData(ApiRoutes.users, (res: any) => {
      this.users = res
      if (!this.user) {
        this.user = this.users.find(u => u.sub == this.api.getToken().sub)
        console.log(this.user)
      }
    })



    setTimeout(() => {
      console.log(this.user)
      if(this.user) {
        this.api.getElementById(ApiRoutes.point, this.user.sub, (res: any) => {
          if(res) {
            this.userPoint = res
            console.log(this.userPoint)
          }
        })
      }
    }, 1500)


    this.api.getData(ApiRoutes.partner, (res: any) => {
      this.partners = res
      console.log(this.partners)
    })

    this.api.getData(ApiRoutes.prodotti, (res: any) => {
      if (res) {
        this.prodotti = res
        console.log(this.prodotti)
      }
    })

  }

  goToProdotti() {
    this.router.navigate(['/prodotti'])
  }

  goToProdotto(id: any) {
    this.router.navigate(['prodotto/' + id])
  }

  goToPartner(id) {
    this.router.navigate(['partner/' + id])
  }

  showQrCode() {
    if (this.lastCredits == false) {
      this.qrCode = true
    }
  }

  closeQrCode() {
    this.qrCode = false
  }

  showLastCredits() {
    if (this.qrCode == false) {
      this.lastCredits = true
    }
  }

  closeLastCredits() {
    this.lastCredits = false
  }

  goToPartners() {
    this.router.navigate(['partners'])
  }
}
