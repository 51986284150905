import { Component, OnInit } from '@angular/core';
import {User} from "../../models/User";
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiRoutes} from "../../common/api-routes";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user: User
  u_id: string

  constructor(public api: ApiService, public route: ActivatedRoute, public router: Router) {
    this.u_id = route.snapshot.params['id']
    console.log(this.u_id)
  }

  ngOnInit() {

    this.api.getElementById(ApiRoutes.user, this.u_id, (res: any) => {
      this.user = res
    })
    setTimeout(() => {
      console.log(this.user)
    }, 500)
  }

  goToSteps(u_id: string) {
    this.router.navigate(['/step1/' + this.u_id])
  }

}
