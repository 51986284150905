import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {ProdottoComponent} from "./components/prodotto/prodotto.component";
import {FormProdottoComponent} from "./components/form-prodotto/form-prodotto.component";
import {NegozioGuard} from "./guards/negozio.guard";
import {PartnerComponent} from "./components/partner/partner.component";
import {ProfileComponent} from "./components/profile/profile.component";

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'step1' + '/:id',
    loadChildren: () => import('./pages/registration/step1/step1.module').then( m => m.Step1PageModule)
  },
  {
    path: 'step2' + '/:id',
    loadChildren: () => import('./pages/registration/step2/step2.module').then( m => m.Step2PageModule)
  },
  {
    path: 'step3' + '/:id',
    loadChildren: () => import('./pages/registration/step3/step3.module').then( m => m.Step3PageModule)
  },
  {
    path: 'prodotti',
    loadChildren: () => import('./pages/prodotti/prodotti.module').then( m => m.ProdottiPageModule)
  },
  {
    path: 'prodotto' + '/:id',
    component: ProdottoComponent
  },
  {
    path: 'add_prodotto',
    component: FormProdottoComponent,
    canActivate: [NegozioGuard]
  },
  {
    path: 'edit_prodotto' + '/:id',
    component: FormProdottoComponent,
    canActivate: [NegozioGuard]
  },
  {
    path: 'partner' + '/:id',
    component: PartnerComponent,
  },
  {
    path: 'user' + '/:id',
    component: ProfileComponent,
  },
  {
    path: 'partners',
    loadChildren: () => import('./pages/partners/partners.module').then( m => m.PartnersPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
