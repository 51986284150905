import { Component, OnInit } from '@angular/core';
import {Prodotto} from "../../models/Prodotto";
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {ApiRoutes} from "../../common/api-routes";
import {Partner} from "../../models/Partner";

@Component({
  selector: 'app-form-prodotto',
  templateUrl: './form-prodotto.component.html',
  styleUrls: ['./form-prodotto.component.scss'],
})
export class FormProdottoComponent implements OnInit {
  prodotto: Prodotto = {}
  u_id = null
  file: File
  partner: Partner[]
  constructor(public api: ApiService, public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.u_id = this.route.snapshot.params['id']
    console.log(this.u_id)

    if(this.u_id) {
      this.api.getElementById(ApiRoutes.prodotto, this.u_id, (res: any) => {
        this.prodotto = res
        console.log(this.prodotto)
      })
    }

    this.api.getData(ApiRoutes.partner, (res: any) => {
      this.partner = res
      console.log(this.partner)
    })
  }

  handler(e) {
    this.file = e.target.files[0]
    console.log(this.file)
  }

  saveProdotto() {
    // this.api.post(this.prodotto, (res: any) => {
    //   this.router.navigate([''])
    //   this.ngOnInit()
    // })
    console.log(this.file)
    this.api.postProdotto(ApiRoutes.prodotto_app, this.prodotto.nome, this.prodotto.crediti, this.prodotto.descrizione, this.prodotto.indirizzo, this.prodotto.telefono, this.prodotto.whatsapp, this.prodotto.partner_id, this.file).subscribe(
      (event: any) => {
        if(typeof event === 'object') {
          this.router.navigate([''])
          this.ngOnInit()
        }
      }
    )
  }

  editProdotto() {
    this.api.putProdotto(this.u_id, ApiRoutes.edit_prodotto, this.prodotto.nome, this.prodotto.crediti, this.prodotto.descrizione, this.prodotto.indirizzo, this.prodotto.telefono, this.prodotto.whatsapp, this.prodotto.partner_id, this.file).subscribe(
      (event: any) => {
        if(typeof event === 'object') {
          this.router.navigate([''])
          this.ngOnInit()
        }
      }
    )
  }


}
