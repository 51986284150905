export class ApiRoutes {
  public static prodotti = 'prodotti'
  public static prodotto = 'prodotto'
  public static edit_prodotto = 'edit_prodotto_app'
  public static prodotto_app = 'prodotto_app'
  public static partner = 'partner'
  public static users = 'users'
  public static point = 'point'
  public static delete_prodotto = 'delete_prodotto_app'
  public static user = 'user'
  public static questionario = 'questionario'
  public static prodotti_partner = 'prodotti_partner'
}
